
import { computed, defineComponent, reactive } from "vue";
import { storesManageService, userService } from "@/service";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "@/store";
import { Dialog } from "vant";
export default defineComponent({
  setup() {
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const userInfo = computed(() => store.state.userInfo);
    const storeInfo = reactive<any>({
      data: null,
      async getUserInfo() {
        const { data } = await userService.userInfo({
          user_id: route.query.userId,
          user_information: 1
        });
        storeInfo.data = data.data;
      }
    });
    storeInfo.getUserInfo();
    return {
      storeInfo,
      userInfo,
      deleteUser() {
        Dialog.confirm({
          message: "是否删除？",
          confirmButtonText: "删除"
        }).then(async () => {
          await storesManageService.delUser({
            id: storeInfo.data.id,
            job_state: "2"
          });
          router.back();
        });
      }
    };
  }
});
